import { LivepagesService } from "@app/services/livepages.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as LivepageActions from './livepages.actions';
import { catchError, from, map, mergeMap, of } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class LivepageEffects {
    constructor(
        private action$: Actions,
        private livepageService: LivepagesService,
    ) {}

    listLivepage$ = createEffect(() => this.action$.pipe(
        ofType(LivepageActions.listLivepages),
        mergeMap(({ merchantId }) =>
            from(this.livepageService.listLivepages(merchantId)).pipe(
                map(livepages => LivepageActions.listLivepagesSuccess({livepages: livepages})),
                catchError(error => of(LivepageActions.listLivepagesFailure({ error })))
            )
        )
    ))

    getLivepage$ = createEffect(() => this.action$.pipe(
        ofType(LivepageActions.getLivepage),
        mergeMap(({ getLivepage }) =>
            from(this.livepageService.getLivepage(getLivepage)).pipe(
                map(livepage => LivepageActions.getLivepageSuccess({livepage: livepage})),
                catchError(error => of(LivepageActions.getLivepageFailure({ error })))
            )
        )
    ))

    searchLivepage$ =  createEffect(() => this.action$.pipe(
        ofType(LivepageActions.searchLivepage),
        mergeMap(({ searchLivepage }) =>
            from(this.livepageService.searchLivepage(searchLivepage)).pipe(
                map(livepages => LivepageActions.searchLivepageSuccess({livepages: livepages})),
                catchError(error => of(LivepageActions.searchLivepageFailure({ error })))
            )
        )
    ))

    readElements$ = createEffect(() => this.action$.pipe(
        ofType(LivepageActions.readElements),
        mergeMap(({ readElements }) =>
            from(this.livepageService.readElements(readElements)).pipe(
                map(elements => LivepageActions.readElementsSuccess({ elements: elements })),
                catchError(error => of(LivepageActions.readElementsFailure(error)))
            )
        )
    ))
}

import { Livepage } from "@app/models/livepage.model";
import * as LivepageActions from "./livepages.actions"
import { createReducer, on } from "@ngrx/store";
import { LivepageElement } from "@app/models/livepage-element.model";

export interface LivepageState {
    livepage: Livepage | null;
    livepages: Livepage [] | null;
    elements: LivepageElement[] | null;
    error: any | null;
}

export const initialState: LivepageState = {
    livepage: null,
    livepages: null,
    elements: null,
    error: null
}

export const reducer = createReducer(
    initialState,
    on(LivepageActions.listLivepagesSuccess, (state, { livepages }) => ({...state, livepages})),
    on(LivepageActions.listLivepagesFailure, (state, { error }) => ({...state, error})),

    on(LivepageActions.getLivepageSuccess, (state, { livepage }) => ({...state, livepage})),
    on(LivepageActions.getLivepageFailure, (state, { error }) => ({...state, error})),
    on(LivepageActions.searchLivepageSuccess, (state, { livepages }) => ({...state, livepages})),
    on(LivepageActions.searchLivepageFailure, (state, { error }) => ({...state, error})),

    on(LivepageActions.readElementsSuccess, (state, { elements }) => ({...state, elements})),
    on(LivepageActions.readElementsFailure, (state, { error }) => ({...state, error})),
)

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, map } from 'rxjs';

import { DbPath } from '@app/shared/utils/db-path';
import { Tag } from '@app/models/tag.model';
import { GetTag, UpsertTag } from '@app/store/tags/tags.models';

@Injectable({
	providedIn: 'root'
})
export class TagsService {

	constructor(
		private afs: AngularFirestore,
		private storage: AngularFireStorage
	) { }

	listTags( merchantId: string ): Observable<any> {
		return this.afs.collection<any>(DbPath.Merchants).doc(merchantId)
			.collection(DbPath.Tags, ref => ref.orderBy('createdOn'))
			.snapshotChanges().pipe(
				map(snaps => snaps.map(snap => ({ id: snap.payload.doc.id, ...snap.payload.doc.data() })))
			);
	}

	getTag( tag: GetTag ) : Observable<any> {
		return this.afs.collection(DbPath.Merchants).doc(tag.merchantId)
			.collection(DbPath.Tags)
			.doc(tag.tagId)
			.get().pipe(
				map(doc => <Tag>({id: doc.id, ...doc.data()}))
			);
	}

	async createTag( newTag: UpsertTag ) {
		return await this.afs.collection(DbPath.Merchants)
			.doc(newTag.merchantId)
			.collection(DbPath.Tags)
			.add(newTag.tag).then(tagRef => tagRef.id);
	}

	async updateTag( updateTag: UpsertTag ) {
		return await this.afs.collection(DbPath.Merchants)
			.doc(updateTag.merchantId)
			.collection(DbPath.Tags)
			.doc(updateTag.tag.id)
			.set(updateTag.tag, { merge: true });
	}

	async deleteTag( tag: GetTag ) {
		return await this.afs.collection(DbPath.Merchants)
			.doc(tag.merchantId)
			.collection(DbPath.Tags)
			.doc(tag.tagId)
			.delete();
	}
}

import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { TagsService } from "@app/services/tags.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, from, map, mergeMap, of } from "rxjs";
import * as TagsActions from "./tags.actions";

@Injectable()
export class TagsEffects {
	constructor(
		private actions$: Actions,
		private tagService: TagsService,
		private storage: AngularFireStorage
	) { }

	listTag$ = createEffect(() => this.actions$.pipe(
		ofType(TagsActions.listTags),
		mergeMap(({ merchantId }) => 
			from(this.tagService.listTags(merchantId)).pipe(
				map((tags) => TagsActions.listTagsSuccess({ tags: tags })),
				catchError(error => of(TagsActions.listTagsFailure({ error })))
			)
		)
	));

	getTag$ = createEffect(() => this.actions$.pipe(
		ofType(TagsActions.getTag),
		mergeMap(({ getTag }) => 
			from(this.tagService.getTag(getTag)).pipe(
				map((tag) => TagsActions.getTagSuccess({ tag: tag })),
				catchError(error => of(TagsActions.getTagFailure({ error })))
			)
		)
	));

	createTag$ = createEffect(() => this.actions$.pipe(
		ofType(TagsActions.createTag),
		mergeMap(({ newTag }) =>
			from(this.tagService.createTag(newTag)).pipe(
				map((tagId) => TagsActions.createTagSuccess({ tagId: tagId })),
				catchError(error => of(TagsActions.createTagFailure({ error })))
			)
		)
	));

	updateTag$ = createEffect(() => this.actions$.pipe(
		ofType(TagsActions.updateTag),
		mergeMap(({ updateTag }) =>
			from(this.tagService.updateTag(updateTag)).pipe(
				map(() => TagsActions.updateTagSuccess({ updateTag })),
				catchError(error => of(TagsActions.updateTagFailure({ error })))
			)
		)
	));

	deleteTag$ = createEffect(() => this.actions$.pipe(
		ofType(TagsActions.deleteTag),
		mergeMap(({ deleteTag }) =>
			from(this.tagService.deleteTag(deleteTag)).pipe(
				map(() => TagsActions.deleteTagSuccess({ deleteTag })),
				catchError(error => of(TagsActions.deleteTagFailure({ error })))
			)
		)
	));
}
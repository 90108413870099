import { createReducer, on } from '@ngrx/store';
import * as TagsActions from './tags.actions';
import { Tag } from '@app/models/tag.model';

export interface TagState {
    // Add additional state properties as needed
    tags: Tag[] | null;
    error: any | null;
}

export const initialState: TagState = {
    tags: null,
    error: null
};


export const reducer = createReducer(
    initialState,
	on(TagsActions.listTagsSuccess, (state, { tags }) => ({...state, tags})),
    on(TagsActions.listTagsFailure, (state, { error }) => ({...state, error})),
    on(TagsActions.createTagSuccess, (state, { tagId }) => ({...state, tagId})),
    on(TagsActions.createTagFailure, (state, { error }) => ({...state, error}))
);
import { Livepage } from "@app/models/livepage.model";
import { createAction, props } from "@ngrx/store";
import { GetLivepage, ReadElements, SearchLivepage } from "./livepages.models";
import { LivepageElement } from "@app/models/livepage-element.model";

export const listLivepages = createAction(
    '[Livepages] List Livepages',
    props<{merchantId: string}>()
)

export const listLivepagesSuccess = createAction(
    '[Livepages] List Livepages Success',
    props<{livepages: Livepage[]}>()
)

export const listLivepagesFailure = createAction(
    '[Livepages] List Livepages Failure',
    props<{error: any}>()
)

export const getLivepage = createAction(
    '[Livepages] Get Livepage',
    props<{getLivepage: GetLivepage}>()
)

export const getLivepageSuccess = createAction(
    '[Livepages] Get Livepage Success',
    props<{livepage: Livepage}>()
)

export const getLivepageFailure = createAction(
    '[Livepages] Get Livepage Failure',
    props<{error: any}>()
)

export const searchLivepage = createAction(
    '[Livepages] Search Livepage',
    props<{searchLivepage: SearchLivepage}>()
)

export const searchLivepageSuccess = createAction(
    '[Livepages] Search Livepage Success',
    props<{livepages: Livepage []}>()
)

export const searchLivepageFailure = createAction(
    '[Livepages] Search Livepage Failure',
    props<{error: any}>()
)

export const readElements = createAction(
    '[Livepage] Read Elements',
    props<{ readElements: ReadElements}>()
)

export const readElementsSuccess = createAction(
    '[Livepage] Read Elements Success',
    props<{ elements: LivepageElement[] }>()
)

export const readElementsFailure = createAction(
    '[Livepage] Read Elements Failure',
    props<{ error: any }>()
)

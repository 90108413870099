import { Tag } from "@app/models/tag.model";
import { createAction, props } from "@ngrx/store";
import { GetTag, UpsertTag } from "./tags.models";

// Retrieve tags list
export const listTags = createAction(
	'[Tags] List Tags',
	props<{merchantId: string}>()
);

export const listTagsSuccess = createAction(
	'[Tags] List Tags Success',
	props<{tags: Tag[]}>()
);

export const listTagsFailure = createAction(
	'[Tags] List Tags Failure',
	props<{error: any}>()
);

// Retrieve tag by id
export const getTag = createAction(
	'[Tags] Get Tag',
	props<{getTag: GetTag}>()
);

export const getTagSuccess = createAction(
	'[Tags] Get Tag Success',
	props<{tag: Tag}>()
);

export const getTagFailure = createAction(
	'[Tags] Get Tag Failure',
	props<{error: any}>()
);

// Create tag
export const createTag = createAction(
	'[Tags] Create Tag',
	props<{newTag: UpsertTag}>()
);

export const createTagSuccess = createAction(
	'[Tags] Create Tag Success',
	props<{tagId: string}>()
);

export const createTagFailure = createAction(
	'[Tags] Create Tag Failure',
	props<{error: any}>()
);

// Update tag
export const updateTag = createAction(
	'[Tags] Update Tag',
	props<{updateTag: UpsertTag}>()
);

export const updateTagSuccess = createAction(
	'[Tags] Update Tag Success',
	props<{updateTag: UpsertTag}>()
);

export const updateTagFailure = createAction(
	'[Tags] Update Tag Failure',
	props<{error: any}>()
);

// Delete tag 
export const deleteTag = createAction(
	'[Tags] Delete Tag',
	props<{deleteTag: GetTag}>()
);

export const deleteTagSuccess = createAction(
	'[Tags] Delete Tag Success',
	props<{deleteTag: GetTag}>()
);

export const deleteTagFailure = createAction(
	'[Tags] Delete Tag Failure',
	props<{error: any}>()
);
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Livepage } from '@app/models/livepage.model';
import { DbPath } from '@app/shared/utils/db-path';
import { GetLivepage, ReadElements, SearchLivepage } from '@app/store/livepages/livepages.models';
import { Observable, filter, map, switchMap } from 'rxjs';
import { Constants } from '@app/shared/utils/constant';
import {ApiService} from "@app/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class LivepagesService {

  constructor(
    private afs: AngularFirestore,
    private apiService: ApiService
  ) { }

  listLivepages(merchantId: string) {
    return this.afs.collection(DbPath.Merchants).doc(merchantId)
      .collection(DbPath.Livepages, ref =>
        ref
          .where('available', '==', true)
          .orderBy('default', 'desc')
      )
          .snapshotChanges().pipe(
        map(snaps => snaps.map(snap => <Livepage>({id: snap.payload.doc.id, ...snap.payload.doc.data()})))
      )
  }

  getLivepage(data: GetLivepage) {
    const livepageByPermaLink = this.afs.collection(DbPath.Merchants).doc(data.merchantId)
      .collection(DbPath.Livepages, ref => ref.where('permalink', '==', data.livepageId))
      .snapshotChanges().pipe(
        map(snaps => snaps.length > 0 ? snaps[0] : null)
      );

    return livepageByPermaLink.pipe(
      map(c => c != null),
      switchMap(hasLivepage => {
        if(hasLivepage) {
          return livepageByPermaLink.pipe(
            filter(result => !!result),
            map(c => <Livepage>({id: c.payload.doc.id, ...c.payload.doc.data()}))
          )
        }

        return this.afs.collection(DbPath.Merchants).doc(data.merchantId)
          .collection(DbPath.Livepages).doc(data.livepageId)
          .snapshotChanges().pipe(
            map(c => <Livepage>({id: c.payload.id, ...c.payload.data()}))
          )
      })
    )
  }

  searchLivepage(data: SearchLivepage) {
    return this.afs.collection(DbPath.Merchants).doc(data.merchantId)
      .collection(DbPath.Livepages, ref =>
        ref.where('trigram', 'array-contains-any', data.trigram.split(' '))
            .where('type', '==',
                Constants.POST_TYPE.ADVANCE)
      )
      .snapshotChanges().pipe(
        map(snaps => snaps.map(snap => <Livepage>({id: snap.payload.doc.id, ...snap.payload.doc.data()})))
      )
  }

  readElements(data: ReadElements): Observable<any> {
    return this.afs.collection(DbPath.Merchants)
        .doc(data.merchantId)
        .collection(DbPath.Livepages)
        .doc(data.livepageId)
        .collection(DbPath.Elements, ref => ref.orderBy('order', 'asc'))
        .snapshotChanges().pipe(
            map(snaps => snaps.map(snap => ({id: snap.payload.doc.id, ...snap.payload.doc.data()})))
        )
  }

  getLivepageFromApi(merchantRef: string, livepageRef: string): Observable<any>{
    return this.apiService.getData(`merchants/${merchantRef}/livepages/${livepageRef}`);
  }
}

import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LivepageState } from "./livepages.reducers";

export const selectLivepageState = createFeatureSelector<LivepageState>('livepage');

export const getLivepages = createSelector(
  selectLivepageState,
  (state) => state.livepages
)

export const selectLivepage = createSelector(
    selectLivepageState,
    (state) => state.livepage
)

export const getElements = createSelector(
  selectLivepageState,
  (state) => state.elements
)
